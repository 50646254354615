import React, { FC } from 'react';
import classNames from 'classnames';
import { ImageService, EmptyPicture } from '@wix/members-area-commons-ts';
import { MediaPlatformImage } from '@wix/members-domain-ts';

import {
  Nullable,
  ProfileImage,
  InjectedClassNameProps,
  InjectedDataHookProps,
} from '../../../types';
import * as styles from './Avatar.scss';

interface DefaultPictureProps {
  layoutType: ProfileImage;
}

interface UserPictureProps {
  url: string | MediaPlatformImage;
}

type AvatarProps = DefaultPictureProps &
  InjectedDataHookProps &
  InjectedClassNameProps & {
    name: Nullable<string>;
    url: Nullable<string | MediaPlatformImage>;
  };

const isLayoutRound = (layoutType: ProfileImage) =>
  layoutType === ProfileImage.Round;

const DefaultPicture: FC<DefaultPictureProps> = ({ children, layoutType }) => (
  <div className={classNames(styles.innerContainer, styles.background)}>
    <div className={classNames(styles.innerContainer, styles.overlay)}>
      <EmptyPicture className={styles.svg} />
      <div
        className={classNames(styles.innerContainer, {
          [styles.round]: isLayoutRound(layoutType),
        })}
      />
      <div
        className={classNames(styles.innerContainer, styles.innerOverlay, {
          [styles.round]: isLayoutRound(layoutType),
        })}
      />
      {children}
    </div>
  </div>
);

const UserPicture: FC<UserPictureProps> = ({ children, url }) => (
  <div
    className={classNames(styles.picture, styles.innerContainer)}
    style={{
      backgroundImage: `url(${new ImageService().getImageUrl({ image: url })})`,
    }}
  >
    {children}
  </div>
);

const Avatar: FC<AvatarProps> = ({
  url,
  layoutType,
  children,
  className = '',
  dataHook,
  name,
}) => (
  <div
    data-hook={dataHook}
    className={classNames(className, styles.container, {
      [styles.round]: isLayoutRound(layoutType),
    })}
  >
    {url ? (
      <UserPicture url={url}>{children}</UserPicture>
    ) : name ? (
      <div className={styles.nameLetter}>
        {name[0]}
        {children}
      </div>
    ) : (
      <DefaultPicture layoutType={layoutType}>{children}</DefaultPicture>
    )}
  </div>
);

export default Avatar;
