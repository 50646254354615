import pica from 'pica';

import { maxImageHeight, maxImageWidth } from '../constants/image';
import fixImageOrientation from './fix-image-orientation';

interface ResizeImageResponse {
  width: number;
  height: number;
  imageUrl: string;
}

const resizeOptions = { alpha: true, quality: 3 };

export const resizeImage = (
  file: File,
  maxWidth = maxImageWidth,
  maxHeight = maxImageHeight,
) => {
  const canvas = document.createElement('canvas');
  const img = document.createElement('img');
  const reader = new FileReader();
  let resolveFn: (resolveResponse: ResizeImageResponse) => void;

  reader.onload = ({ target }) =>
    fixImageOrientation(target?.result as string, (fixedImageSrc) => {
      img.src = fixedImageSrc;
    });

  img.onload = async () => {
    let width = img.width;
    let height = img.height;

    if (width > height && width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    } else if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    canvas.width = width;
    canvas.height = height;

    const resizer = pica();
    const resizedCanvas = await resizer.resize(img, canvas, resizeOptions);
    const response = {
      width,
      height,
      imageUrl: resizedCanvas.toDataURL(file.type),
    };

    resolveFn(response);
  };

  reader.readAsDataURL(file);

  return new Promise<ResizeImageResponse>((resolve) => {
    resolveFn = resolve;
  });
};
