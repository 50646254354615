import React, { Component, ReactNode, MouseEvent, createRef } from 'react';
import classNames from 'classnames';

import {
  Position,
  DataHook,
  InjectedClassNameProps,
  InjectedDataHookProps,
} from '../../../types';
import screenContext from '../../../services/screen-context';
import * as styles from './Popover.scss';

type VoidHandler = () => void;

type PositionMap = { [key in Position]?: string };

type WithInjectedProps = InjectedClassNameProps & InjectedDataHookProps;

enum KeyCodes {
  ArrowDown = 40,
  ArrowUp = 38,
  Esc = 27,
  Tab = 9,
}

const noopHandler = () => {};

const classNameByPosition: PositionMap = {
  [Position.Top]: styles.popoverContainerTop,
  [Position.Bottom]: styles.popoverContainerBottom,
  [Position.Left]: styles.popoverContainerLeft,
};

const classNameByAlignment: PositionMap = {
  [Position.Right]: styles.alignRight,
  [Position.Left]: styles.alignLeft,
};

export interface PopoverProps extends WithInjectedProps {
  className?: string;
  ariaLabel?: string;
  position?: Position;
  alignment: Position;
  dynamicPositioning: boolean;
  content?: ReactNode;
  children?: ReactNode;
  onClose: VoidHandler;
  onOpen: VoidHandler;
}

interface PopoverState {
  position: Position;
  marginTop: number;
  isVisible: boolean;
}

export class Popover extends Component<PopoverProps, PopoverState> {
  static defaultProps = {
    alignment: Position.Right,
    dynamicPositioning: true,
    onClose: noopHandler,
    onOpen: noopHandler,
  };

  private popoverRef = createRef<HTMLDivElement>();
  private triggerButtonRef = createRef<HTMLButtonElement>();

  constructor(props: PopoverProps) {
    super(props);

    this.state = {
      position: props.position || Position.Bottom,
      marginTop: 0,
      isVisible: false,
    };
  }

  componentDidUpdate = (_: PopoverProps, prevState: PopoverState) => {
    if (prevState.isVisible !== this.state.isVisible) {
      this.setFocus();
    }
  };

  render = () => {
    const { className, children, dataHook } = this.props;

    return (
      <button
        ref={this.triggerButtonRef}
        data-hook={dataHook}
        type="button"
        className={classNames(className, styles.popoverBtn)}
        aria-pressed={this.state.isVisible}
        aria-haspopup="menu"
        aria-label={this.props.ariaLabel}
        onClick={this.handlePopoverToggle}
      >
        {children}
        {this.state.isVisible && this.renderPopover()}
      </button>
    );
  };

  private readonly getAllMenuItems = () => {
    const popoverElement = this.popoverRef.current;
    const menuItemNodes = popoverElement?.querySelectorAll("[role='menuitem']");
    return Array.prototype.slice.call(menuItemNodes ?? []);
  };

  private readonly focusTriggerButton = () => {
    const currentTriggerButtonRef = this.triggerButtonRef.current;
    if (currentTriggerButtonRef) {
      currentTriggerButtonRef.focus();
    }
  };

  private readonly focusFirstMenuItem = () => {
    const menuItems = this.getAllMenuItems();
    if (menuItems.length > 0) {
      menuItems[0].focus();
    }
  };

  private readonly setFocus = () => {
    if (this.state.isVisible) {
      setTimeout(() => this.focusFirstMenuItem());
    } else {
      setTimeout(() => this.focusTriggerButton());
    }
  };

  private readonly moveNext = (currentElem: Element | null) => {
    const allMenuItems = this.getAllMenuItems();
    const indexOfCurrentItem = allMenuItems.indexOf(currentElem);
    const newIndex = (indexOfCurrentItem + 1) % allMenuItems.length;
    allMenuItems[newIndex].focus();
  };

  private readonly movePrev = (currentElem: Element | null) => {
    const allMenuItems = this.getAllMenuItems();
    const indexOfCurrentItem = allMenuItems.indexOf(currentElem);
    const newIndex =
      (indexOfCurrentItem + allMenuItems.length - 1) % allMenuItems.length;
    allMenuItems[newIndex].focus();
  };

  private readonly operateMenu = (event: KeyboardEvent) => {
    const keyCode = event.keyCode;

    if (keyCode === KeyCodes.ArrowDown) {
      event.preventDefault();
      this.moveNext(document.activeElement);
    }

    if (keyCode === KeyCodes.ArrowUp) {
      event.preventDefault();
      this.movePrev(document.activeElement);
    }

    if (keyCode === KeyCodes.Esc || keyCode === KeyCodes.Tab) {
      event.preventDefault();
      this.handlePopoverToggle(event);
    }
  };

  private readonly toggleListeners = (isVisible: boolean) => {
    const domObject = window.document;

    if (isVisible) {
      domObject.addEventListener('click', this.handlePopoverToggle);
      domObject.addEventListener('keydown', this.operateMenu);
      return;
    }
    domObject.removeEventListener('click', this.handlePopoverToggle);
    domObject.removeEventListener('keydown', this.operateMenu);
  };

  private readonly widgetBlurListener = (isVisible: boolean) =>
    isVisible
      ? window.addEventListener('blur', this.handlePopoverToggle)
      : window.removeEventListener('blur', this.handlePopoverToggle);

  private readonly adjustPosition = (clientY: number) => {
    const { dynamicPositioning } = this.props;
    const currentPopover = this.popoverRef.current;
    const popoverHeight = currentPopover ? currentPopover.offsetHeight : 0;

    if (dynamicPositioning) {
      const context = screenContext(clientY, popoverHeight);
      this.setState({
        position: context.fit ? Position.Bottom : Position.Top,
        marginTop: context.top,
      });
    }
  };

  private readonly handlePopoverToggle = (event: Event | MouseEvent) => {
    event.preventDefault();

    const { isVisible } = this.state;
    const { onClose, onOpen } = this.props;
    const { clientY } = event as MouseEvent;

    isVisible ? onClose() : onOpen();

    if (!document) {
      return;
    }

    this.toggleListeners(!isVisible);
    this.widgetBlurListener(!isVisible);

    this.setState({ isVisible: !isVisible }, () => {
      this.adjustPosition(clientY);
    });
  };

  private readonly renderPopover = () => {
    const { content, alignment } = this.props;
    const { position, marginTop } = this.state;

    const containerClasses = [
      styles.popoverContainer,
      styles.popoverLink,
      classNameByPosition[position],
      classNameByAlignment[alignment],
    ];

    return (
      <div
        data-hook={DataHook.Popover}
        className={classNames(...containerClasses)}
        ref={this.popoverRef}
        style={{ marginTop }}
      >
        <div
          className={classNames(styles.popover, styles.popoverLink)}
          role="menu"
          aria-live="polite"
          aria-collapsed={!this.state.isVisible}
        >
          {content}
        </div>
      </div>
    );
  };
}

export default Popover;
