import React, { FC } from 'react';
import classNames from 'classnames';

import { InjectedClassNameProps, InjectedDataHookProps } from '../../../types';
import * as styles from './RepositionButton.scss';

type InjectedProps = InjectedClassNameProps & InjectedDataHookProps;

interface RepositionButtonProps extends InjectedProps {
  text: string;
  onClick?: () => void;
}

export const RepositionButton: FC<RepositionButtonProps> = ({
  dataHook,
  className,
  text,
  onClick,
}) => {
  return (
    <button
      data-hook={dataHook}
      className={classNames(styles.button, className)}
      onClick={onClick}
    >
      <i className={styles.icon} />
      <span className={styles.text}>{text}</span>
    </button>
  );
};
