import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';

import { BaseComponentProps, DataHook, VoidHandler } from '../../../../types';
import { absorbArguments } from '../../../../services/event-handler';
import styles from './SignUpWidget.scss';

interface SignUpProps extends Pick<BaseComponentProps, 'isMobile' | 't'> {
  onClick: VoidHandler;
}

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return [isMounted];
};

const SignUp: FC<SignUpProps> = ({ isMobile, t, onClick }) => {
  const [isMounted] = useIsMounted();
  const handleSignUpClick = absorbArguments(onClick);

  const containerClassName = classNames(
    styles.container,
    isMobile ? styles.mobile : styles.desktop,
  );

  return (
    <div data-hook={DataHook.SignUpWidget} className={containerClassName}>
      <div className={styles.headerCont}>
        <div className={styles.header}>{t('sign-up.title')}</div>
        <div className={styles.content}>{t('sign-up.content')}</div>
        <div className={styles.controls}>
          <button
            data-hook={isMounted && DataHook.SignUpWidgetCTA}
            type="button"
            className={classNames(styles.signUpButton)}
            onClick={handleSignUpClick}
          >
            {t('sign-up.action')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
