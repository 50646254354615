import React, { Component } from 'react';
import { Overlay } from 'react-overlays';
import classNames from 'classnames';

import { Position } from '../../../types';
import scrollParent from '../../../services/scroll-parent';
import * as styles from './Tooltip.scss';

const DEFAULT_TIMEOUT = 500;
const FADE_DURATION = 200;
const TOOLTIP_WIDTH = 7;

export interface TooltipProps {
  text: string;
  placement?: Position;
  timeout?: number;
  className?: string;
}

class Tooltip extends Component<TooltipProps> {
  public static defaultProps: Partial<TooltipProps> = {
    placement: Position.Top,
    timeout: DEFAULT_TIMEOUT,
  };

  state = {
    isVisible: false,
  };

  private contentRef: null | HTMLElement = null;
  private tooltipRef: null | HTMLElement = null;
  private arrowRef: null | HTMLElement = null;
  private timeout = 0;

  componentDidUpdate() {
    setTimeout(() => this.replaceArrowToContentCenter(), 0);
  }

  componentWillUnmount() {
    this.handleMouseLeave();
  }

  render() {
    const { children, placement, className } = this.props;

    return (
      <div
        ref={this.setContentRef}
        className={className}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
        <Overlay
          target={this.contentRef}
          container={scrollParent()}
          placement={placement}
          show={this.state.isVisible}
        >
          {this.renderTooltip()}
        </Overlay>
      </div>
    );
  }

  setTooltipRef = (ref: HTMLElement | null) => {
    this.tooltipRef = ref;
  };

  setContentRef = (ref: HTMLElement | null) => {
    this.contentRef = ref;
  };

  setArrowRef = (ref: HTMLElement | null) => {
    this.arrowRef = ref;
  };

  private readonly replaceArrowToContentCenter = () => {
    if (!this.contentRef || !this.arrowRef || !this.tooltipRef) {
      return;
    }

    const isVertical =
      this.props.placement === Position.Top ||
      this.props.placement === Position.Bottom;

    const contentBoundaries = this.contentRef.getBoundingClientRect();
    const tooltipBoundaries = this.tooltipRef.getBoundingClientRect();

    const contentCenter = isVertical
      ? contentBoundaries.left +
        contentBoundaries.width / 2 -
        tooltipBoundaries.left
      : contentBoundaries.top +
        contentBoundaries.height / 2 -
        tooltipBoundaries.top;

    const arrowStyle = isVertical
      ? `left: ${contentCenter - TOOLTIP_WIDTH}px`
      : `top: ${contentCenter - TOOLTIP_WIDTH}px`;

    this.arrowRef.setAttribute('style', arrowStyle);
  };

  private readonly handleMouseEnter = () => {
    this.timeout = window.setTimeout(() => {
      this.setState({ isVisible: true });
    }, this.props.timeout);
  };

  private readonly handleMouseLeave = () => {
    clearTimeout(this.timeout);

    if (this.tooltipRef) {
      this.tooltipRef.classList.remove(styles.fullOpacity);
      setTimeout(() => {
        this.setState({ isVisible: false });
      }, FADE_DURATION);
    } else {
      this.setState({ isVisible: false });
    }
  };

  private readonly renderTooltip = () => {
    const { text, placement = Position.Top } = this.props;
    const { isVisible } = this.state;

    const tooltipClasses = classNames(
      styles.contentContainer,
      styles[placement],
      isVisible && styles.fullOpacity,
    );

    return (
      <div ref={this.setTooltipRef} className={tooltipClasses}>
        <div className={styles.content}>{text}</div>
        <div ref={this.setArrowRef} className={styles.triangleWrapper}>
          <div className={styles.triangle} />
        </div>
      </div>
    );
  };
}

export default Tooltip;
