import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';

import {
  Position,
  Orientation,
  DataHook,
  InjectedTranslateProps,
} from '../../../types';
import Tooltip from '../Tooltip';
import Popover from '../Popover';
import MoreIcon from './MoreIcon';
import * as styles from './MoreButton.scss';

export interface MoreButtonProps extends InjectedTranslateProps {
  className?: string;
  iconContainerClass?: string;
  icon?: ReactNode;
  position?: Position;
  alignment: Position;
  orientation?: Orientation;
  isMobile: boolean;
  isRtlLocale: boolean;
  dynamicPositioning?: boolean;
}

interface MoreButtonState {
  isExpanded: boolean;
}

class MoreButton extends Component<MoreButtonProps, MoreButtonState> {
  public static defaultProps = {
    icon: <MoreIcon className={styles.moreIcon} />,
  };

  state = { isExpanded: false };

  render() {
    const {
      className,
      isMobile,
      dynamicPositioning,
      children,
      position,
      alignment,
    } = this.props;

    return (
      <Popover
        dataHook={DataHook.MoreActionsCTA}
        className={classNames(className, styles.container)}
        position={position}
        alignment={alignment}
        dynamicPositioning={dynamicPositioning}
        content={children}
        onOpen={this.handlePopoverOpen}
        onClose={this.handlePopoverClose}
      >
        <div className={styles.more}>
          {!isMobile && !this.state.isExpanded
            ? this.renderIconWithTooltip()
            : this.renderIcon()}
        </div>
      </Popover>
    );
  }

  private readonly handlePopoverOpen = () => {
    this.setState({ isExpanded: true });
  };

  private readonly handlePopoverClose = () => {
    this.setState({ isExpanded: false });
  };

  private readonly renderIcon = () => {
    const {
      iconContainerClass,
      icon,
      orientation = Orientation.Vertical,
      t,
    } = this.props;

    return (
      <div
        aria-label={t('more-button.more-actions')}
        className={classNames(
          styles.icon,
          iconContainerClass,
          styles[orientation],
        )}
      >
        {icon}
      </div>
    );
  };

  private readonly renderIconWithTooltip = () => (
    <Tooltip
      text={this.props.t('more-button.tooltip')}
      placement={this.props.isRtlLocale ? Position.Right : Position.Left}
    >
      {this.renderIcon()}
    </Tooltip>
  );
}

export default MoreButton;
