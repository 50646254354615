import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { Member } from '@wix/members-domain-ts';

import { InjectedClassNameProps } from '../../../types';
import isOverflowing from '../../../services/is-overflowing';
import Tooltip from '../Tooltip';
import * as styles from './MemberName.scss';

export interface MemberNameProps extends InjectedClassNameProps {
  member?: Member;
  name: string;
  tooltipClass?: string;
  goToMemberPage?: Function;
}

class MemberName extends Component<MemberNameProps> {
  state = { isNameTooltipVisible: false };

  private nameWrapper = createRef<HTMLDivElement>();

  componentDidMount() {
    this.updateNameTooltipVisibility();
  }

  UNSAFE_componentWillReceiveProps() {
    this.updateNameTooltipVisibility();
  }

  render = () =>
    this.state.isNameTooltipVisible
      ? this.renderNameWithTooltip(this.props.name)
      : this.renderName(this.props.name);

  private readonly renderName = (name: string) => (
    <span
      ref={this.nameWrapper}
      className={classNames(this.props.className, styles.nameButton)}
    >
      {name}
    </span>
  );

  private readonly renderNameWithTooltip = (name: string) => (
    <Tooltip text={name} className={this.props.tooltipClass}>
      {this.renderName(name)}
    </Tooltip>
  );

  private readonly updateNameTooltipVisibility = () => {
    const isNameTooltipVisible = this.nameWrapper.current
      ? isOverflowing(this.nameWrapper.current)
      : false;

    if (this.state.isNameTooltipVisible !== isNameTooltipVisible) {
      this.setState({ isNameTooltipVisible });
    }
  };
}

export default MemberName;
