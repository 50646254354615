import { ChangeEventHandler } from 'react';

import {
  ImageChangeOptions,
  CoverRepositionOptions,
  VoidHandler,
  Nullable,
} from '../types';
import { resizeImage } from './image-resize';

type ValueChangeHandler<T = string> = (value: T) => void;

export const absorbArguments = (handler: VoidHandler) => () => handler();

export const forwardTargetValue = (
  handler: ValueChangeHandler,
): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => ({ target }) =>
  handler(target.value);

export const forwardResizedImage = (
  handler: ValueChangeHandler<ImageChangeOptions>,
): ChangeEventHandler<HTMLInputElement> => async ({ target }) => {
  const file = target && target.files && target.files[0];

  if (file) {
    const { width, height, imageUrl } = await resizeImage(file);
    target.value = '';
    handler({ width, height, imageUrl, name: file.name });
  }
};

export const forwardCoverRepositionAttributes = (
  handler: ValueChangeHandler<CoverRepositionOptions>,
  coverWidth: number,
): EventListener => (event) => {
  const { movementX, movementY } = event as MouseEvent;
  const movement = { x: movementX, y: movementY };

  handler({ coverWidth, movement, isMobile: false });
};

export const getMobileCoverRepositionAttributesForwarder = (
  handler: ValueChangeHandler<CoverRepositionOptions>,
  coverWidth: number,
): EventListener => {
  let repositionLastX: Nullable<number> = null;
  let repositionLastY: Nullable<number> = null;

  const forwardMobileCoverRepositionAttributes = () => (event: TouchEvent) => {
    if (repositionLastX !== null && repositionLastY !== null) {
      const movement = {
        x: -1 * (repositionLastX - event.changedTouches[0].clientX),
        y: -1 * (repositionLastY - event.changedTouches[0].clientY),
      };

      handler({ coverWidth, movement, isMobile: true });
    }

    repositionLastX = event.changedTouches[0].clientX;
    repositionLastY = event.changedTouches[0].clientY;
  };

  return forwardMobileCoverRepositionAttributes;
};
