import React, { FC } from 'react';
import { InjectedClassNameProps } from '../../../types';

export interface ChatIconProps extends InjectedClassNameProps {
  size: 'small' | 'medium' | 'large';
}

const ChatIcon: FC<ChatIconProps> = ({ className, size }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size === 'large' ? 26 : 18}
    height={size === 'large' ? 26 : 18}
    viewBox="-1 -1 26 26"
  >
    <path
      fill-rule="evenodd"
      d="M23.7998326,9.64732311 C24.3247306,12.4352111 23.8092653,15.315708 22.3480674,17.7600036 C21.9559123,18.4137615 21.5012672,19.0292754 20.9903014,19.5981932 C20.9051389,19.6873714 20.8567442,19.8044993 20.8545247,19.9268081 L20.8545247,22.9254191 C20.8703633,23.2606814 20.7185782,23.5823977 20.4480178,23.7870311 C20.1774574,23.9916645 19.8222678,24.0533893 19.4967587,23.9523407 L16.3112306,22.9254191 C16.2095914,22.891765 16.0995393,22.891765 15.9979,22.9254191 C11.2600576,24.5710478 5.98113646,23.1402236 2.77011429,19.3400963 C-0.440907868,15.539969 -0.904062208,10.1752569 1.60955398,5.89733781 C4.12317017,1.61941868 9.08127132,-0.665811631 14.0334372,0.171066066 C18.9856031,1.00794376 22.8831588,4.78971152 23.7998326,9.64732311 Z M6.50000001,14 C7.32842713,14 8.00000001,13.3284271 8.00000001,12.5 C8.00000001,11.6715729 7.32842713,11 6.50000001,11 C5.67157288,11 5.00000001,11.6715729 5.00000001,12.5 C5.00000001,13.3284271 5.67157288,14 6.50000001,14 Z M11.5,14 C12.3284271,14 13,13.3284271 13,12.5 C13,11.6715729 12.3284271,11 11.5,11 C10.6715729,11 10,11.6715729 10,12.5 C10,13.3284271 10.6715729,14 11.5,14 Z M16.5,14 C17.3284271,14 18,13.3284271 18,12.5 C18,11.6715729 17.3284271,11 16.5,11 C15.6715729,11 15,11.6715729 15,12.5 C15,13.3284271 15.6715729,14 16.5,14 Z"
    />
  </svg>
);

export default ChatIcon;
