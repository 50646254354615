import React, { FC } from 'react';
import classNames from 'classnames';
import { RoleId, RoleAction } from '@wix/members-domain-ts';

import { InjectedTranslateProps } from '../../../types';
import roleIcons from '../../../constants/role-icons';
import RoleActionIcon from './RoleActionIcon';
import RoleActionLink, { RoleActionLinkClickHandler } from './RoleActionLink';
import RoleActionLinkText from './RenderActionLinkText';
import PopoverContentSeparator from './PopoverContentSeparator';
import * as styles from './PopoverContent.scss';

export type OnRoleAction = (roleId: RoleId) => void;

interface BaseProps extends InjectedTranslateProps {
  onRoleAction: OnRoleAction;
}

interface RenderRoleActionProps extends BaseProps {
  action: string;
  roleId: RoleId;
}

export interface PopoverContentProps extends BaseProps {
  rolesActions: RoleAction[];
  isMobile: boolean;
}

const RoleActionItem: FC<RenderRoleActionProps> = ({
  roleId,
  action,
  t,
  onRoleAction,
}) => {
  const onClick: RoleActionLinkClickHandler = (event) => {
    event.preventDefault();
    onRoleAction(roleId);
  };

  return (
    <RoleActionLink
      key={action}
      dataHook={roleId}
      isMenuItem={true}
      onClick={onClick}
    >
      <RoleActionIcon t={t}>{roleId}</RoleActionIcon>
      <RoleActionLinkText>{t(action)}</RoleActionLinkText>
    </RoleActionLink>
  );
};

const PopoverContent: FC<PopoverContentProps> = ({
  rolesActions,
  isMobile,
  t,
  onRoleAction,
}) => {
  const roleIconsKeys = Object.keys(roleIcons);
  const withIcons = rolesActions.filter(({ roleId }) =>
    roleIconsKeys.includes(roleId),
  );
  let withoutIcons = rolesActions.filter(
    ({ roleId }) => !roleIconsKeys.includes(roleId),
  );
  const isSeparatorNeeded = withIcons.length > 0 && withoutIcons.length > 0;

  // Render "Manage badges" at the end if it's there
  const manageBadgesAction = withoutIcons.find(
    (r) => r.roleId === RoleId.MANAGE_BADGES,
  );
  if (manageBadgesAction) {
    withoutIcons = withoutIcons.filter((r) => r !== manageBadgesAction);
    withoutIcons.push(manageBadgesAction);
  }

  const rolesActionsWithIcons = withIcons.map((r) => (
    <RoleActionItem
      key={r.action}
      roleId={r.roleId}
      action={r.action}
      t={t}
      onRoleAction={onRoleAction}
    />
  ));
  const rolesActionsWithoutIcons = withoutIcons.map((r) => (
    <RoleActionItem
      key={r.action}
      roleId={r.roleId}
      action={r.action}
      t={t}
      onRoleAction={onRoleAction}
    />
  ));

  // Add separator before "Manage badges" if there are more roles without icons
  if (manageBadgesAction && rolesActionsWithoutIcons.length > 1) {
    rolesActionsWithoutIcons.splice(
      rolesActionsWithoutIcons.length - 1,
      0,
      <PopoverContentSeparator key="separator-1" />,
    );
  }

  const allItems = [
    ...rolesActionsWithIcons,
    isSeparatorNeeded && <PopoverContentSeparator key="separator-2" />,
    ...rolesActionsWithoutIcons,
  ];

  const classes = [
    styles.actionsContainer,
    rolesActions.length > 1 ? styles.actionsContainerManyItems : null,
    rolesActions.length <= 1 ? styles.actionsContainerOneItem : null,
    { [styles.mobile]: isMobile },
  ].filter((x) => x);

  return <div className={classNames(...classes)}>{...allItems}</div>;
};

export default PopoverContent;
