import rotate from 'rotate-component';
import flip from 'flip-component';

enum Operation {
  None = 'none',
  FlipX = 'flip-x',
  FlipY = 'flip-y',
}

const orientations = [
  { op: Operation.None, degrees: 0 },
  { op: Operation.FlipX, degrees: 0 },
  { op: Operation.None, degrees: 180 },
  { op: Operation.FlipY, degrees: 0 },
  { op: Operation.FlipX, degrees: 90 },
  { op: Operation.None, degrees: 90 },
  { op: Operation.FlipX, degrees: -90 },
  { op: Operation.None, degrees: -90 },
];

const isRotated = (n: number) => [5, 6, 7, 8].indexOf(n) > -1;

const exIfRotate = (image: HTMLImageElement, orientationIndex: number) => {
  const orientation = orientations[orientationIndex - 1];
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // dims
  if (isRotated(orientationIndex)) {
    canvas.height = image.width;
    canvas.width = image.height;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  // flip
  if (orientation.op === Operation.FlipX) {
    flip(canvas, true, false);
  }

  if (orientation.op === Operation.FlipY) {
    flip(canvas, false, true);
  }

  // rotate
  if (orientation.degrees) {
    rotate(ctx, {
      degrees: orientation.degrees,
      x: canvas.width / 2,
      y: canvas.height / 2,
    });

    if (isRotated(orientationIndex) && ctx) {
      const d = canvas.width - canvas.height;
      ctx.translate(d / 2, -d / 2);
    }
  }

  if (ctx) {
    ctx.drawImage(image, 0, 0);
  }

  return canvas.toDataURL('image/jpeg');
};

export default exIfRotate;
