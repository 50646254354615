import React, { FC } from 'react';
import { RoleAction } from '@wix/members-domain-ts';

import { Position, DataHook, InjectedTranslateProps } from '../../../types';
import MoreButton from '../MoreButton';
import PopoverContent, { OnRoleAction } from './PopoverContent';

interface MemberMoreButtonProps extends InjectedTranslateProps {
  containerClass: string;
  iconContainerClass?: string;
  rolesActions: RoleAction[];
  position?: Position;
  alignment: Position;
  isMobile: boolean;
  isRtlLocale: boolean;
  dynamicPositioning?: boolean;
  onRoleAction: OnRoleAction;
}

const MemberMoreButton: FC<MemberMoreButtonProps> = ({
  containerClass,
  iconContainerClass = '',
  rolesActions,
  position,
  alignment,
  isMobile,
  isRtlLocale,
  dynamicPositioning = false,
  t,
  onRoleAction,
}) =>
  rolesActions.length ? (
    <div data-hook={DataHook.MoreActionsWrapper} className={containerClass}>
      <MoreButton
        iconContainerClass={iconContainerClass}
        dynamicPositioning={dynamicPositioning}
        position={position}
        alignment={alignment}
        isRtlLocale={isRtlLocale}
        isMobile={isMobile}
        t={t}
      >
        <PopoverContent
          rolesActions={rolesActions}
          isMobile={isMobile}
          t={t}
          onRoleAction={onRoleAction}
        />
      </MoreButton>
    </div>
  ) : null;

export default MemberMoreButton;
